import React, { useEffect, useState, lazy } from 'react';
import { Provider } from 'react-redux';
import store from './redux/store';
import { BrowserRouter as Router } from 'react-router-dom';
import Views from './views';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { THEME_CONFIG } from './configs/AppConfig';
import { db } from './auth/FirebaseAuth';
import ErrorOne from './underMaintenance';
import { GlobalProvider } from './providers/globalProvider';
import { doc, onSnapshot } from "firebase/firestore";

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

function App() {

  // const [maintenance, setMaintenance] = useState(true);
  

  useEffect(() => {
    onLoad();
  }, []);

  const onLoad = async () => {
    onSnapshot(doc(db, 'settings', 'global'), (doc) => {
      if (doc.exists) {
        var globalData = doc.data();
        console.log('MAINTENANCE MODE IN APP js', globalData.mMode);

        if (localStorage.getItem('mModeCheck') == null) {
          localStorage.setItem('mModeCheck', 'false')
        }

        if (globalData.mMode == true && localStorage.getItem('mModeCheck') == 'false') {
          console.log('MAINTENANCE MODE', globalData.mMode);
          window.location = '/error';
          localStorage.setItem('mModeCheck', 'true');
        }
        else if (globalData.mMode == false && localStorage.getItem('mModeCheck') == 'true') {
          console.log('MAINTENANCE MODE', globalData.mMode);
          window.location = '/';
          localStorage.setItem('mModeCheck', 'false');
        }
        // mMode = globalData.mMode;
      }
    })

  }

  return (
    <div className="App">
      <Provider store={store}>
        <ThemeSwitcherProvider themeMap={themes} defaultTheme={THEME_CONFIG.currentTheme} insertionPoint="styles-insertion-point">
          <GlobalProvider>
            <Router>
              <Switch>
                <Route path="/error" component={ErrorOne} />
                <Route path="/" component={Views} />
                {/* {maintenance ?
                <Redirect to={'/error'}/>
                :
                <Redirect to={'/'}/> 
              } */}
                {/* <Route path="/" render={() => (
                maintenance == false ? (
                  <Redirect to="/" />
                ) : (
                  <ErrorOne />
                )
              )} /> */}
              </Switch>
            </Router>
          </GlobalProvider>
        </ThemeSwitcherProvider>
      </Provider>
    </div>
  );
}

export default App;
