import { createContext, useState, useEffect } from "react";
import { db,auth } from "auth/FirebaseAuth";

export const GlobalContext = createContext({});

export const GlobalProvider = ({ children }) => {
  const [globalData, setGlobalData] = useState({});
  const [serversData, setServersData] = useState([]);
  const [currentServer, setCurrentServer] = useState({});
  var servers = [];

  useEffect(async () => {
    
    // db.collection("users").doc(globalData.user?.uid).collection("vms").onSnapshot((snapshot) => {
    //     servers =[];
    //     snapshot.forEach(doc => {
    //       servers.push({ id: doc.id, ...doc.data() });
    //     });
    //     setServersData(servers);
    //     console.log("Servers Data in Global Data");
    //     console.log(servers);
    //   });

  }, [globalData.user]);

      return (
        <GlobalContext.Provider
          value={{
            globalData,
            setGlobalData,
            serversData,
            setServersData,
            currentServer,
            setCurrentServer,
          }}>
          {children}
        </GlobalContext.Provider>
      );
    }